@font-face {
    font-family: 'perfectly_ninetiesitalic';
    src: url('./fonts/PerfectlyNineties-Italic.woff2') format('woff2'),
         url('./fonts/PerfectlyNineties-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'perfectly_ninetiesregular';
    src: url('./fonts/PerfectlyNineties-Regular.woff2') format('woff2'),
         url('./fonts/PerfectlyNineties-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body{
    font-family: 'Red Hat Display', sans-serif;
    background-color: black;
    color: white;
    font-size: 18px;;
}

h1, h2, h3{
    font-family: 'perfectly_ninetiesregular', sans-serif;
    color: #bbfdf0;
    -webkit-text-stroke-width: 1.75px;
    -webkit-text-stroke-color: #bbfdf0;
}

h1{
    font-size: 50px;
    margin-top: 20px;
}

a{
    color: #bbfdf0;
}

a:hover{
    color: white;
}

.container-skinny,
.container-thin{
    width: 100%;
    margin: auto;
    padding: 0 30px;
}

@media (min-width: 768px) {

    h1{
        font-size: 60px;
        margin-top: 0px;
    }

    .container-skinny{
       max-width: 992px;
    }

    .container-skinny .side-by-side img{
        max-width: 350px;
    }

    .container-thin{
        max-width: 1092px;
    }

    .container-thin .side-by-side img{
        max-width: 450px;
    }
}


@media (max-width: 767px) {
    .container-skinny.scroll-sm{
        padding: 0px;
    }

    .container-skinny.scroll-sm h1{
        padding-left: 30px;
    }

    .container-skinny.scroll-sm #nft-container{
        flex-wrap: nowrap;
        overflow-x: auto;
        margin: 0px;
        padding: 25px;
        -webkit-overflow-scrolling: touch;
    }

    .container-skinny.scroll-sm #nft-container::-webkit-scrollbar {
        display: none;
    }

    .container-skinny.scroll-sm #nft-container .nft-token{
        min-width: 40%;
    }

}

.side-by-side{
    display: flex;
    flex-direction: column;
}


.btn{
    font-weight: 900;
    box-sizing: border-box;
    border: 3px solid transparent;
    font-size: 20px;
}

.btn:hover{
    background-color: black;
    color: #bbfdf0;
    border-color: #bbfdf0;
}

.btn-connect,
.btn-mint{
    background-color: #bbfdf0;
    color: black;  
    width: 100%;
}

.btn-bury{
    font-size: 12px;
    background-color: #bbfdf0;
    width: 100%;
}


@media (min-width: 768px) {
    .btn-connect,
    .btn-mint{
        width: auto;
    }

    .btn-connect{
        position: absolute;
        top: 30px;
        right: 30px;
    }
}

.sec-blue{
    background-color: #054392;
}

#hero{
    height: 45vw;
    width: 100%;
    background: url('./img/grim-weepers-banner-background.png');
    background-size: cover;
    background-position: bottom center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#hero .logo{
    width: 40vw;
}

.contract-link{
    font-size: 11px;
    margin-top: 30px;
    color: #cccccc;
}

.contract-link a{
    color: #e6e6e6;
    text-decoration: none;
}

.contract-link a:hover{
    color: #cccccc;
}

@media (min-width: 768px) {
    .side-by-side{
        flex-direction: row;
    }

    #hero{
        height: 33.33vw;
        width: 100%;
    }

    #hero .logo{
        width: 25vw;
    }
}

.border-round{
    border-radius: 10px;
    overflow: hidden;
}

.modal-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: none;
}

.modal-wrapper.show{
    display: block;
}

.modal-wrapper .modal-message{
    position: absolute;
    width: 33.33%;
    height: 33.33%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
}

.ratio{
    position: relative;
    background-position: center center;
    background-size: cover;
    border-radius: 6px;
}
  
.ratio-1x1:before{
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
}

.ratio-3x1:before{
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33%;
}

#error-message{
    margin-top: 20px;
    background-color: red;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    display: none;
}

#error-message.show{
    display: block;
}

#nft-container{
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

#nft-container .nft-token{
    padding: 5px;
    width: 33.33%;
    margin-bottom: 30px;
    font-size: 14px;
}

#nft-container .nft-token img{
    width: 100%;
}

@media (min-width: 768px) {
    #nft-container .nft-token{
        width: 20%;
    }
}

#nft-container .nft-token .prereveal{
    border: 1px solid rgba(0,0,0,0.5);
}

#nft-container .nft-token .image-link{
    display: block;
    border-radius: 5px;
    overflow: hidden;
}

#nft-container .nft-token .attribute-holder{
    display: flex;
    flex-wrap: wrap;
    margin-left: -2.5px;
    margin-right: -2.5px;
}

#nft-container .nft-token .attribute-holder .attribute{
    font-size: 8pt;
    border-radius: 3px;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-transform: uppercase;
    margin: 0 2.5px 5px 2.5px;
    padding: 2.5px 5px;
}
.mint-price{
    color: white;
}

@media (min-width: 768px) {
    .mint-price{
        color: white;
        min-width: 160px;
    }
}

.accountId{
    background-color: #054392;
    color: white;
    font-weight: 900;
}

.amt{
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
}

.amt .checkcontainer{
    display: flex;
    height: 40px;
    width: 40px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 5px;
}

.amt .checkcontainer input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.amt .checkcontainer input ~ .radioval{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #000;
    z-index: 1;
    font-size: 20px;
    font-weight: 900;
}

.amt .checkcontainer input ~ .radiobtn{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #bbfdf0;
    z-index: 0;
}

.amt .checkcontainer input:checked ~ .radiobtn,
.amt .checkcontainer.checked .radiobtn{
    background-color: #054392;
}

.amt .checkcontainer input:checked ~ .radioval,
.amt .checkcontainer.checked .radioval{
    color: white;
}

.mint-btn-wrapper{
    margin-left: 0px;
    margin-top: 15px;
}

.mint-btn-wrapper .btn.btn-mint{
    margin-right: 10px;
}

@media (min-width: 768px) {
    .mint-btn-wrapper{
        margin-left: 10px;
        margin-top: 0px;
    }
}

.collage-1{
    position: relative;
}

.collage-1 .secondary-img{
    position: absolute;
    width: 33.33%;
    height: auto;
    z-index: 2;
    bottom: -20px;
    left: 60px;
    filter: drop-shadow(0 0px 60px rgba(0,0,0,0.8));
}


.collage-1 .secondary-img img{
    width: 100%;
}

@media (min-width: 768px) {
    .collage-1 .secondary-img{
        width: 33.33%;
        bottom: -60px;
        left: 60px;
    }
}

.collage-1 .main-img{
    position: relative;
    z-index: 1;
}

footer{
    padding: 60px 0;
}

footer .footer-links{
    margin-right: -10px;
    margin-left: -10px;
}

footer a{
    color: white;
    padding: 0 10px;
}

footer a:hover{
    color: #cccccc;
}

/* Loader */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  